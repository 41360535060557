import axios from 'axios'
// create an axios instance
const service = axios.create({
  baseURL: window.parent.location.origin, // url = base url + request url
  // baseURL: 'http://localhost', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
})

// request interceptor
// service.interceptors.request.use(
//   config => {
//     // do something before request is sent

//     if (store.getters.token) {
//       // let each request carry token
//       // ['X-Token'] is a custom headers key
//       // please modify it according to the actual situation
//       config.headers['Authorization'] = 'Bearer ' + getToken()
//     }
//     return config
//   },
//   error => {
//     // do something with request error
//     console.log(error) // for debug
//     return Promise.reject(error)
//   }
// )

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    return response
  },
  error => {
    console.log(error.response) // for debug

    // check permission
    // if (error.response.status === 401) {
    //   Message({
    //     message: 'Phiên đăng nhập hết hạn',
    //     type: 'error',
    //     duration: 5 * 1000
    //   })
    //   store.dispatch('user/resetToken').then(() => {
    //     location.reload()
    //   })
    // }
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service
